/* App.css */

/* Importing a stylish Google Font */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

body {
  font-family: 'Playfair Display', serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9; /* Light background */
}

/* Stylish header with a white background and centered content */
.header {
  background-color: white; /* White header background */
  color: #660e63; /* Dark green text color */
  padding: 40px;
  text-align: center;
  border-bottom: 1px solid #ddd; /* Optional: light border for a subtle effect */
}

.title {
  font-size: 3rem; /* Larger, elegant font size */
  font-weight: 700; /* Bold for emphasis */
  margin: 0;
}


/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }

  .nav a {
    display: block;
    margin: 10px 0;
  }
}
