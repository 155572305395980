/* About.css */

/* Style for the about section */
.about {
    background-color: white; /* Same background as the header for consistency */
    color: #2e8b57; /* Dark green text to match the theme */
    text-align: center; /* Centered text to align with the header and contact sections */
    padding: 50px 20px; /* Generous padding for a clean, modern look */
    border-top: 1px solid #ddd; /* Optional border for separation from previous sections */
  }
  
  /* Heading styling */
  .about h2 {
    font-size: 2.5rem; /* Large, modern font size */
    margin-bottom: 20px; /* Space below the heading */
    font-weight: 700; /* Bold heading for emphasis */
    color: #2e8b57; /* Dark green to match the rest of the site */
  }
  
  /* Paragraph text styling */
  .about p {
    font-size: 1.2rem; /* Slightly larger text for readability */
    margin: 10px 0; /* Spacing between the paragraphs */
    color: #2e8b57; /* Consistent dark green color */
    line-height: 1.6; /* Line height for improved readability */
    max-width: 800px; /* Limiting the width for better readability on large screens */
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .about h2 {
      font-size: 2rem; /* Adjust the heading size on smaller screens */
    }
  
    .about p {
      font-size: 1.1rem; /* Slightly smaller text for mobile */
    }
  }
  