/* ImageGrid.css */

/* Container for the grid */
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Always have 4 columns */
  gap: 10px; /* Space between images */
  padding: 20px;
  /* Remove the fixed height to allow natural page flow */
}

/* Image item container */
.image-item {
  width: 100%; /* Take full width of the grid cell */
  height: 0; /* Set height to 0 to be controlled by padding */
  padding-bottom: 100%; /* Makes the height proportional to the width (1:1 ratio) */
  position: relative; /* Allows image to be positioned within the square */
}

/* Ensuring all images are squares and fill their grid cells equally */
.image-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire grid cell without distortion */
}
