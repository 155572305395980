/* Contact.css */

/* Style for the contact section */
.contact {
    background-color: white; /* Match the white background used in the header */
    color: #660e63; /* Dark green text to align with the header style */
    text-align: center; /* Center the text */
    padding: 50px 20px; /* Generous padding for modern spacing */
    border-top: 1px solid #ddd; /* Optional border for separation from previous section */
  }
  
  /* Heading styling */
  .contact h2 {
    font-size: 2.5rem; /* Large, elegant font size */
    margin-bottom: 20px; /* Space below the heading */
    font-weight: 700; /* Bold weight for emphasis */
    color: #660e63; /* Dark green to match the header */
  }
                       
  /* Paragraph text styling */
  .contact p {
    font-size: 1.2rem; /* Slightly larger than normal text */
    margin: 10px 0; /* Space between each line */
    color: #660e63; /* Consistent color with the rest of the design */
    line-height: 1.6; /* Increase line height for readability */
  }
  
  /* Add some styling for links, in case you later make email/phone clickable */
  .contact a {
    color: #660e63; /* Slightly darker green for links */
    text-decoration: none;
  }
  
  .contact a:hover {
    text-decoration: underline; /* Underline on hover for better accessibility */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .contact h2 {
      font-size: 2rem; /* Adjust heading size on smaller screens */
    }
  
    .contact p {
      font-size: 1.1rem; /* Slightly smaller paragraph text for mobile */
    }
  }
  